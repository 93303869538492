
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
  import { SearchState } from '@/ts/states/search/searchState'
  import { LookupState } from '@/ts/states/lookup/lookupState'
  import { QueryParams } from '@/ts/api/queryParams'
  import { ViewStatus } from '@/ts/enums/viewStatus'
  import { DateFormatter } from '@/ts/formatters/dateFormatter'
  import MultiSelect from '@/components/multiselect/MultiSelect.vue'
  
  @Component({
    components: { MultiSelect }
  })
  export default class CustomerSearchForm extends Vue {

  /** PROPERTIES */

    @Prop()
    public state!: SearchState


    /** PRIVATE PROPERTIES */

    protected lookupState = new LookupState(this.$store)
    protected queryParams = this.state.persons.queryParams
    

    /** OBSERVERS */

    @Watch('state.persons.queryParams', { immediate: false, deep: true })
    protected onQueryParamsChanged(queryParams: QueryParams) {
      this.queryParams = queryParams
    }

    /** COMPUTED PROPERIES */

    protected get currentDateStandardlization() {
      return DateFormatter.currentDateStandardlization();
    }

    protected get advancedSearchIcon() {
      return this.state.isAdvancedSearch ? 'chevron-up' : 'chevron-down';
    }

    protected get advancedSearchText() {
      return `${this.state.isAdvancedSearch ? 'Hide' : 'Show'} advanced search`
    }

    protected get searchText() {
      return (this.status == ViewStatus.IN_PROGRESS) ? 'Searching...' : 'Update Search'
    }

    protected get status() {
      return this.state.persons.status
    }

    /** EVENTS */

    protected onReset() {
      this.state.clear()
    }

    protected onSubmit() {
      this.queryParams.currentPage = 1
      this.state.fetch(this.queryParams)
    }
  }
